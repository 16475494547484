<template>
  <CookieButton>
    <template #cookieBtns>
      <div class="d-flex align-items-center justify-content-between w-100">
        <button
          class="btn btn-secondary mr-1 float-left"
          @click="emptyTrash()"
          v-if="status == 'trash' && $can(`${$route.meta.permission}.delete`)"
        >
          {{ $t("Empty Trash") }}
        </button>
      </div>
      <button
        :disabled="selectedCases.length == 0"
        :class="{ 'disabled-btn': selectedCases.length == 0 }"
        class="btn btn-secondary mr-1"
        @click="downloadCaseFiles()"
        v-if="$can(`${$route.meta.permission}.download-original`)"
      >
        {{ $t("Download") }}
      </button>
      <button
        :disabled="selectedCases.length == 0"
        class="btn btn-secondary mr-1"
        @click="
          status == 'trash' || status == 'finished'
            ? updateStatuses('active')
            : updateStatuses('finished')
        "
        :class="{ 'disabled-btn': selectedCases.length == 0 }"
        v-if="$can(`${$route.meta.permission}.edit`)"
      >
        {{
          $t(
            `${
              status == "trash" || status == "finished" ? "Active" : "Finished"
            }`
          )
        }}
      </button>
      <button
        @click="status == 'trash' ? destroyMultiple() : updateStatuses('trash')"
        :class="{ 'disabled-btn': selectedCases.length == 0 }"
        :disabled="selectedCases.length == 0"
        class="btn btn-secondary"
        v-if="
          status == 'trash'
            ? $can(`${$route.meta.permission}.delete`)
            : $can(`${$route.meta.permission}.edit`)
        "
      >
        {{ $t("Delete") }}
      </button>
    </template>
  </CookieButton>
</template>
<script>
import { mapGetters } from "vuex";
import FileService from "@/services/file.service";
import ElementIcon from "@/components/elements/Icon.vue";
import StarRating from "@/components/StarRating.vue";

import CookieButton from "@/components/elements/CookieButton.vue";
export default {
  components: {
    ElementIcon,
    StarRating,
    FileService,
    CookieButton,
  },
  props: {
    status: {
      type: String,
    },
    selectedCases: {
      type: Array,
      default: [],
    },
    rows: {
      type: Array,
      default: [],
    },
  },
  emits: ["loadItems", "isCompleted"],
  computed: {},
  methods: {
    async emptyTrash() {
      const confirmed = await this.showConfirmationDialog({
        title: this.$t("Do you want to empty the trash?"),
        subText: this.$t("Yes empty it!"),
      });

      if (confirmed) {
        await this.$store.dispatch("caseLists/emptyTrash").finally(() => {
          this.$emit("loadItems");
        });
      }
    },
    async downloadCaseFiles() {
      var cases = this.rows.filter((row) =>
        this.selectedCases.includes(row.id)
      );
      // Loop through each case using forEach
      cases.forEach((caseItem) => {
        this.downloadFiles([caseItem.files["upper"], caseItem.files["lower"]]);
      });
      this.$emit("isCompleted");
    },
    downloadFiles(array) {
      FileService.downloadFiles(array);
    },
    async updateStatuses(status) {
      // Set title based on the status
      const title =
        status === "trash"
          ? this.$t("Do you want to delete these records?")
          : this.$t("Do you want to update these records?");
      const subText =
        status === "trash"
          ? this.$t("Yes delete them!")
          : this.$t("Yes update them!");

      const confirmed = await this.showConfirmationDialog({
        title: title,
        subText: subText,
      });

      if (confirmed) {
        await this.$store
          .dispatch("caseLists/updateStatuses", {
            caseIds: this.selectedCases,
            status: status,
          })
          .finally(() => {
            this.$emit("isCompleted");
            this.$emit("loadItems");
          });
      }
    },
    async destroyMultiple() {
      const confirmed = await this.showConfirmationDialog({
        title: this.$t("Do you want to delete these record?"),
        subText: this.$t("Yes delete them!"),
      });

      if (confirmed) {
        await this.$store
          .dispatch("caseLists/deleteCases", {
            caseIds: this.selectedCases,
          })
          .finally(() => {
            this.$emit("isCompleted");
            this.$emit("loadItems");
          });
      }
    },
  },
};
</script>
