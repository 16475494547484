<template>
  <div>
    <MyCases :status="'active'" />
  </div>
</template>

<script>
import MyCases from "@/components/cases/MyCases.vue";
export default {
  name: "Active",
  components: {
    MyCases,
  },
  beforeRouteEnter(to, from, next) {
    document.body.classList.add("cookie-layout");
    next();
  },
  beforeRouteLeave(to, from, next) {
    document.body.classList.remove("cookie-layout");
    next();
  },
};
</script>
