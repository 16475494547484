<template>
  <div class="stars-container" aria-label="Rating stars">
    <span class="rate"
      ><i
        v-for="(star, index) in stars"
        :key="index"
        @click="!readonly && setRating(index + 1)"
        :class="{
          selected: index + 1 <= selectedRating,
          readonly: readonly,
        }"
        :aria-label="'Star ' + (index + 1)"
      >
        {{ star }}
      </i></span
    >
  </div>
</template>

<script>
export default {
  emits: ["setRatingValue"],
  data() {
    return {
      stars: ["★", "★", "★", "★", "★"],
      selectedRating: this.value,
    };
  },
  methods: {
    setRating(rating) {
      if (this.$can("case-instance.save-rating")) {
        this.selectedRating = rating;
        this.$emit("setRatingValue", rating);
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "No Permission",
            text: "You do not have the permission to rate the case",
            variant: "danger",
            icon: "BellIcon",
          },
        });
      }
    },
  },
  props: {
    value: {
      type: Number | String,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.rate i,
.rate i:hover ~ i {
  color: #000;
  text-shadow: none;
  font-size: 22px;
  transition: color 200ms, text-shadow 200ms;
  transition-delay: 0;
}

.rate:hover i {
  color: #7367f0;
  /* text-shadow: #fc0 0 0 20px; */
}

.rate:hover i:nth-child(2) {
  transition-delay: 30ms;
}

.rate:hover i:nth-child(3) {
  transition-delay: 60ms;
}

.rate:hover i:nth-child(4) {
  transition-delay: 90ms;
}

.rate:hover i:nth-child(5) {
  transition-delay: 120ms;
}

.rate i {
  cursor: pointer;
  font-style: normal;
  color: #000;
}

.rate i.selected {
  color: #7367f0;
}
.rate i:hover {
  color: #7367f0;
}
.stars-container {
  background: transparent;
  width: 120px;
  margin: 0;
  white-space: nowrap;
}
</style>
