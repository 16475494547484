<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <div class="case-left">
        <div class="failed" v-if="caseItem.caseStatus == 'failed'">
          <ElementIcon v-bind:icon="'xIcon'" />
        </div>
        <div class="failed" v-else-if="caseItem.caseStatus == 'unsupported'">
          <ElementIcon v-bind:icon="'xIcon'" />
        </div>
        <span
          class="hour-glass"
          v-else-if="
            caseItem.caseStatus == 'queued' ||
            caseItem.caseStatus == 'in-progress'
          "
          ><img src="@/assets/images/svg/hourglass.gif" alt=""
        /></span>
        <div class="" v-else>
          <img
            v-if="caseItem.results && caseItem.results['upper_image']"
            :src="caseItem.upperImageSrc"
            alt=""
          />
          <div
            v-if="caseItem.results && caseItem.results['upper_image']"
            class="d-flex justify-content-center"
          >
            <StarRating
              :key="caseItem.id"
              :value="
                (caseItem.ratings && caseItem.ratings.upper_case_rating) || 0
              "
              @setRatingValue="setRatingValue($event, caseItem.id, 'upper')"
            />
          </div>
          <h4
            v-if="caseItem.results && caseItem.results['upper_image']"
            class="text-center mt-2"
          >
            {{ $t("Upper") }}
          </h4>
        </div>
      </div>
      <div class="case-right">
        <div class="failed" v-if="caseItem.caseStatus == 'failed'">
          <ElementIcon v-bind:icon="'xIcon'" />
        </div>
        <div class="failed" v-else-if="caseItem.caseStatus == 'unsupported'">
          <ElementIcon v-bind:icon="'xIcon'" />
        </div>
        <span
          class="hour-glass"
          v-else-if="
            caseItem.caseStatus == 'queued' ||
            caseItem.caseStatus == 'in-progress'
          "
          ><img src="@/assets/images/svg/hourglass.gif" alt=""
        /></span>
        <div class="" v-else>
          <img
            v-if="caseItem.results && caseItem.results['lower_image']"
            :src="caseItem.lowerImageSrc"
            alt=""
          />
          <div
            v-if="caseItem.results && caseItem.results['lower_image']"
            class="d-flex justify-content-center"
          >
            <StarRating
              :key="caseItem.id"
              :value="
                (caseItem.ratings && caseItem.ratings.lower_case_rating) || 0
              "
              @setRatingValue="setRatingValue($event, caseItem.id, 'lower')"
            />
          </div>
          <h4
            v-if="caseItem.results && caseItem.results['lower_image']"
            class="text-center mt-2"
          >
            {{ $t("Lower") }}
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ElementIcon from "@/components/elements/Icon.vue";
import StarRating from "@/components/StarRating.vue";
export default {
  components: {
    ElementIcon,
    StarRating,
  },
  props: {
    caseItem: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async setRatingValue(event, id, jawType) {
      await this.$store.dispatch("caseLists/saveRating", {
        caseId: id,
        ...(jawType === "upper"
          ? { upperCaseRating: event }
          : { lowerCaseRating: event }),
      });
    },
  },
};
</script>
