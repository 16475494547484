<template>
  <div>
    <PageHeader :items="items" />
    <div class="mycases create-case">
      <div class="pageheader">
        <h2>
          {{ $t(`${status.charAt(0).toUpperCase() + status.slice(1)} Cases`) }}:
          {{ totalRecords }}
        </h2>
        <div class="pageheader-right">
          <div class="d-flex align-items-center gap-1 filter-by-color">
            <p>{{ $t("Status") }}</p>
            <b-form-group class="mb-0">
              <select class="form-control" v-model="form.caseStatus">
                <option
                  v-for="option in caseStatusOptions"
                  :value="option.value"
                  :key="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </b-form-group>
          </div>
          <div class="search">
            <div class="icon">
              <ElementIcon v-bind:icon="'searchIcon'" />
            </div>
            <input
              type="text"
              v-model="form.search"
              :placeholder="$t('Search for...')"
            />
          </div>
          <div class="divider"></div>
          <div class="d-flex align-items-center gap-1 filter-by-color">
            <p>{{ $t("Filter by") }}</p>
            <b-form-group class="m-0">
              <select class="form-control" v-model="form.selectedFilter">
                <option value="all">{{ $t("All") }}</option>
                <option
                  v-for="flag in existingFlags"
                  :key="flag.id"
                  :value="flag.id"
                  :style="{ color: flag.color }"
                >
                  {{ flag.name }}
                </option>
              </select>
            </b-form-group>
          </div>
          <div class="d-flex align-items-center gap-1 filter-by-color">
            <p>{{ $t("At least") }}</p>
            <StarRating
              :value="filterRatingValue"
              @setRatingValue="setFilterRatingValue"
            />
          </div>
        </div>
      </div>

      <!-- Cases Display -->
      <div class="card case-m-card">
        <div class="card-body">
          <div
            class="d-flex align-items-center justify-content-between mb-2"
            v-if="rows.length > 0"
          >
            <div class="checkbox-s-group">
              <input
                type="checkbox"
                class="checkbox-input"
                id="selectAll"
                v-model="selectAll"
                @change="toggleSelectAll"
              />
              <label for="selectAll" class="checkbox-label m-0">
                {{ $t("Select All Cases") }}
              </label>
            </div>
          </div>
          <b-row class="case-m">
            <b-col
              lg="6"
              md="6"
              cols="12"
              class="mb-2"
              v-for="(caseItem, index) in rows"
              :key="index"
              v-if="rows.length > 0"
            >
              <label class="w-100 h-100" :for="'case-' + caseItem.id">
                <div class="case-card">
                  <div
                    class="d-flex align-items-center justify-content-between mb-2"
                  >
                    <div class="d-flex align-items-center">
                      <div class="checkbox-s-group">
                        <input
                          type="checkbox"
                          class="checkbox-input"
                          :id="'case-' + caseItem.id"
                          v-model="selectedCases"
                          :value="caseItem.id"
                        />
                        <label
                          :for="'case-' + caseItem.id"
                          class="checkbox-label m-0"
                        ></label>
                      </div>
                      <h4>
                        {{ $t("Case") }}:
                        {{ caseItem.name }}
                      </h4>
                    </div>
                    <div class="d-flex align-items-center gap-2">
                      <p class="status-tag" v-if="caseItem.isConnected === 1">
                        {{ $t("Connected") }}
                      </p>
                      <p
                        class="status-tag"
                        :class="caseItem.caseStatus"
                        :title="hoverMessage(caseItem)"
                      >
                        {{ $t(caseItem.caseStatus) }}
                      </p>
                      <h4>
                        {{
                          $dateFormatter(
                            formatDate(caseItem.creation_date),
                            $i18n.locale
                          )
                        }}
                      </h4>
                    </div>
                  </div>

                  <CaseBody :caseItem="caseItem" />
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <div
                      class="tag"
                      :style="{
                        backgroundColor: caseItem.tagColor,
                      }"
                      @click="openModal(caseItem)"
                      :title="caseItem.flag ? caseItem.flag.name : ''"
                      v-if="$can(`${$route.meta.permission}.edit-flag`)"
                    ></div>
                    <ActionDropdown
                      :configuration="configuration"
                      :caseItem="caseItem"
                      :credits="credits"
                      :status="status"
                      @getCredits="getCredits"
                      @loadItems="loadItems"
                      @showCreditsModal="showCreditModal = true"
                      @showCaseModal="showCaseModal = true"
                      @downloadResultModal="downloadResultModal"
                    />
                  </div>
                </div>
              </label>
            </b-col>
            <b-col v-if="rows.length === 0" cols="12" class="text-center"
              ><p>{{ $t(`No ${status} cases found`) }}.</p></b-col
            >
          </b-row>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <!-- Pagination -->
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRecords"
          :per-page="perPage"
          @input="(value) => onPageChange({ currentPage: value })"
          aria-controls="case-list"
        ></b-pagination>
      </div>
      <FooterButtons
        :status="status"
        :rows="rows"
        :selectedCases="selectedCases"
        @loadItems="loadItems"
        @isCompleted="selectedCases = []"
      />
      <!-- B-Modal for editing tag -->
      <b-modal
        id="edit-tag-modal"
        v-model="showModal"
        title="Edit Flag"
        centered
        size="md"
        hide-footer
      >
        <b-form @submit.prevent="updateFlag" class="modal-form">
          <validation-observer ref="simpleRules">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Flag"
                rules="required"
              >
                <label class="form-label input-label" for="flag"
                  >{{ $t("Flag") }}&nbsp;<span style="color: #f00">*</span>
                </label>
                <multi-select
                  v-model="tag.flag"
                  :options="existingFlags"
                  :taggable="true"
                  tag-placeholder="Add a tag"
                  placeholder="Select or add a tag"
                  @tag="addFlag"
                  label="name"
                  track-by="name"
                />
                <small class="text-danger">{{ $t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Set Flag Color">
              <b-dropdown
                id="colorDropdown"
                class="form-control form-dropdown"
                variant="link"
              >
                <template #button-content>
                  <p class="d-flex align-items-center" v-if="tag.tagColor">
                    <span
                      class="mr-1"
                      :style="{
                        backgroundColor: tag.tagColor,
                        width: '20px',
                        height: '20px',
                        display: 'inline-block',
                        borderRadius: '20px',
                      }"
                    >
                    </span>
                    {{ tag.tagColor || "None" }}
                  </p>
                  <p v-else>{{ $t("Select a color") }}</p>
                  <feather-icon icon="ChevronDownIcon" size="20" />
                </template>
                <div class="color-pettle">
                  <b-dropdown-item
                    v-for="color in colors"
                    :key="color.value"
                    @click="tag.tagColor = color.value"
                  >
                    <span
                      class="color-box"
                      :style="{
                        backgroundColor: color.value,
                      }"
                    >
                    </span>
                    <!-- {{ color.name }} -->
                  </b-dropdown-item>
                </div>
              </b-dropdown>
            </b-form-group>
            <div class="d-flex align-items-center justify-content-end mt-2">
              <b-button type="submit" variant="primary">{{
                $t("Save")
              }}</b-button>
            </div>
          </validation-observer>
        </b-form>
      </b-modal>
      <!---===================================-->
      <b-modal
        id="edit-tag-modal"
        v-model="showCreditModal"
        title="Not enough DentalTwin Coins"
        centered
        size="md"
        hide-footer
      >
        {{
          $t("You do not have enough DentalTwin Coins to recalculate the case")
        }}
        <div class="d-flex align-items-center justify-content-end mt-2">
          <b-button @click="showCreditModal = false" variant="primary">{{
            $t("Ok")
          }}</b-button>
        </div>
      </b-modal>
      <!---===================================-->
      <b-modal
        id="edit-tag-modal"
        v-model="showCaseModal"
        title="Not enough DentalTwin Coins"
        centered
        size="md"
        hide-footer
      >
        {{
          $t(
            "You do not have enough DentalTwin Coins, do you want to buy more DentalTwin Coins?"
          )
        }}
        <div class="d-flex align-items-center justify-content-end gap-2 mt-2">
          <b-button @click="showCaseModal = false" variant="primary">
            {{ $t("No") }}
          </b-button>
          <b-button @click="$router.push('/credits')" variant="primary">
            {{ $t("Yes") }}
          </b-button>
        </div>
      </b-modal>
      <b-modal
        id="edit-tag-modal"
        v-model="downloadResultCreditsWarningModal"
        title="Download Result"
        centered
        size="md"
        hide-footer
      >
        {{
          $t("Do you really want to download the Case? It costs") +
          " " +
          downloadResultCreditsWarningModalData.sum +
          " " +
          $t("DentalTwin Coins")
        }}
        <div class="d-flex align-items-center justify-content-end gap-2 mt-2">
          <b-button
            @click="
              () => {
                downloadResultCreditsWarningModalData = {
                  caseItem: {},
                  sum: 0,
                };
                downloadResultCreditsWarningModal = false;
              }
            "
            variant="primary"
          >
            {{ $t("No") }}
          </b-button>
          <b-button
            @click="
              downloadResult(downloadResultCreditsWarningModalData.caseItem)
            "
            variant="primary"
          >
            {{ $t("Yes") }}
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import ElementIcon from "@/components/elements/Icon.vue";
import { BLink, BRow, BCol, BImg, BPagination } from "bootstrap-vue";
import FileService from "@/services/file.service";
import { mapGetters } from "vuex";
import MultiSelect from "vue-multiselect";
import NProgress from "nprogress";
import PageHeader from "@/components/PageHeader.vue";
import ActionDropdown from "@/components/cases/ActionDropdown.vue";
import FooterButtons from "@/components/cases/FooterButtons.vue";
import CaseBody from "@/components/cases/CaseBody.vue";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import StarRating from "@/components/StarRating.vue";
import { debounce } from "@/utils/debounce.js";
export default {
  components: {
    ElementIcon,
    BRow,
    BCol,
    BLink,
    BImg,
    BPagination,
    MultiSelect,
    FooterButtons,
    ActionDropdown,
    PageHeader,
    CaseBody,
    ValidationProvider,
    ValidationObserver,
    StarRating,
  },
  props: {
    status: {
      type: String,
      default: "active",
    },
  },
  data() {
    return {
      templateId: null,
      selectedJaw: "both",
      showModal: false,
      showCaseModal: false,
      showCreditModal: false,
      currentTag: {
        index: null,
        text: "",
        color: "#ffffff",
      },
      intervalId: null,
      previousItems: null,
      currentPage: 1,
      rows: [],
      selectedId: null,
      perPage: 6,
      selectAll: false,
      totalRecords: 0,
      credits: 0,
      selectedCases: [],
      tag: {
        flag: null,
        tagColor: "",
      },
      colors: [
        { name: "Red", value: "#ff0000" },
        { name: "Green", value: "#00ff00" },
        { name: "Blue", value: "#0000ff" },
        { name: "Yellow", value: "#ffff00" },
        { name: "Magenta", value: "#ff00ff" },
        { name: "Cyan", value: "#00ffff" },
        { name: "Maroon", value: "#800000" },
        { name: "Dark Green", value: "#008000" },
        { name: "Navy", value: "#000080" },
        { name: "Gray", value: "#808080" },
        { name: "Orange", value: "#ffa500" },
        { name: "Purple", value: "#800080" },
        { name: "Teal", value: "#008080" },
        { name: "Olive", value: "#808000" },
        { name: "Silver", value: "#c0c0c0" },
        { name: "Gold", value: "#ffd700" },
        { name: "Coral", value: "#ff7f50" },
        { name: "Salmon", value: "#fa8072" },
        { name: "Lavender", value: "#e6e6fa" },
        { name: "Sky Blue", value: "#87ceeb" },
        { name: "Slate Gray", value: "#708090" },
        { name: "Chocolate", value: "#d2691e" },
        { name: "Crimson", value: "#dc143c" },
        { name: "Periwinkle", value: "#ccccff" },
        { name: "Mint", value: "#98ff98" },
        { name: "Peach", value: "#ffcc99" },
        { name: "Ivory", value: "#fffff0" },
        { name: "Plum", value: "#dda0dd" },
        { name: "Turquoise", value: "#40e0d0" },
        { name: "Beige", value: "#f5f5dc" },
        { name: "Orchid", value: "#da70d6" },
        { name: "Tomato", value: "#ff6347" },
      ],
      form: {
        sortBy: "createdAt",
        sortOrder: "desc",
        search: "",
        status: this.status,
        selectedFilter: "all",
        caseStatus: "all",
      },
      filteredCases: [],
      currentTag: {
        color: "",
      },
      existingFlags: [],
      currentUpper: null,
      currentLower: null,
      results: null,
      filterRatingValue: null,
      caseStatusOptions: [
        { value: "all", text: "All" },
        { value: "done", text: "Done" },
        { value: "failed", text: "Failed" },
        { value: "in-progress", text: "In Progress" },
        { value: "in-approval", text: "In Approval" },
        { value: "queued", text: "Queued" },
        { value: "unsupported", text: "Unsupported" },
      ],
      downloadResultCreditsWarningModal: false,
      downloadResultCreditsWarningModalData: { caseItem: {}, sum: 0 },
    };
  },
  computed: {
    ...mapGetters("globalConfiguration", ["configuration"]),
    ...mapGetters("caseLists", ["reportCaseID"]),
    ...mapGetters(["showLoader"]),
    ...mapGetters("auth", ["user"]),
    items() {
      return [
        {
          text: this.$t("Dental Twin"),
          to: "/home",
        },
        {
          text: this.$t("My Cases"),
          to: "/my-cases/" + this.status,
        },
        {
          text: this.$t(
            `${this.status.charAt(0).toUpperCase() + this.status.slice(1)}`
          ),
          active: true,
        },
      ];
    },
  },
  async created() {
    this.debouncedFetch = debounce(async (newValue, oldValue) => {
      try {
        await this.loadItems();
      } catch (e) {
        console.error(e);
      }
    }, 300);
  },
  methods: {
    async setFilterRatingValue(event) {
      this.filterRatingValue = event;
      await this.loadItems();
    },
    async setRatingValue(event, id, jawType) {
      await this.$store.dispatch("caseLists/saveRating", {
        caseId: id,
        ...(jawType === "upper"
          ? { upperCaseRating: event }
          : { lowerCaseRating: event }),
      });
    },

    async getImage(fileId, key, caseItem) {
      try {
        // Fetch the binary data as a Blob
        const response = await FileService.getResultById(fileId);

        // Create a FileReader to convert the Blob to Base64
        const reader = new FileReader();

        reader.onloadend = () => {
          // Get the Base64 string
          const base64data = reader.result;

          // Set the Base64 string to caseItem
          this.$set(caseItem, key, base64data); // Use Vue's reactivity
        };

        // Read the Blob as a Data URL (Base64)
        reader.readAsDataURL(response.data);
      } catch (error) {
        console.error("Error fetching binary file:", error);
      }
    },
    formatDate(timeStamp) {
      // Convert to Date object
      const date = new Date(timeStamp);

      // Format the date (YYYY-MM-DD HH:mm:ss)
      const formattedDate = date.toISOString().split("T")[0];
      const formattedTime = date.toTimeString().split(" ")[0]; // Extract time in HH:mm:ss format

      // Combine date and time
      return `${formattedDate} ${formattedTime}`;
    },
    downloadFiles(array) {
      FileService.downloadFiles(array);
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ pageLength: params.pageLength });
      this.loadItems();
    },
    hoverMessage(caseItem) {
      // Check if the caseStatus is 'failed' or 'unsupported' and if results JSON is not empty
      if (
        ["failed", "unsupported"].includes(caseItem.caseStatus) &&
        caseItem.results
      ) {
        return caseItem.results["message"];
      }
      return ""; // Return empty string if conditions are not met
    },

    onSortChange(params) {
      this.form.sortOrder = params[0].type;
      if (params[0].type == "none") this.form.sortOrder = "asc";
      this.form.sortBy = params[0].field;
      this.loadItems();
    },
    async loadFlags() {
      let response = await this.$store.dispatch("flags/list");
      this.existingFlags = response?.data?.data;
    },
    async updateFlag() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          await this.$store
            .dispatch("caseLists/updateFlag", {
              caseId: this.selectedId,
              tagColor: this.tag.tagColor ?? "#ffffff",
              flag: this.tag.flag?.id ?? null,
            })
            .then((res) => {
              this.loadItems();
              this.showModal = false;
            });
        }
      });
    },

    async loadItems() {
      const response = await this.$store.dispatch("caseLists/list", {
        page: this.page,
        ...this.form,
        perPage: this.pageLength,
        selectedFilter: this.form.selectedFilter,
        starRating: this.filterRatingValue,
      });

      // Make a deep copy of the current data to compare accurately
      const newItems = JSON.parse(JSON.stringify(response?.data?.data));
      // Compare with the previous items
      if (JSON.stringify(newItems) !== JSON.stringify(this.previousItems)) {
        // Update previousItems with a deep copy to avoid modifications
        this.previousItems = JSON.parse(JSON.stringify(newItems));
        this.rows = newItems;
        this.totalRecords = response?.data?.meta?.total;

        // Reload images only if necessary
        this.rows.forEach((caseItem) => {
          if (caseItem.results?.upper_image) {
            this.getImage(
              caseItem.results.upper_image,
              "upperImageSrc",
              caseItem
            );
          }
          if (caseItem.results?.lower_image) {
            this.getImage(
              caseItem.results.lower_image,
              "lowerImageSrc",
              caseItem
            );
          }
        });
      }
    },

    async addFlag(newTag) {
      await this.$store.dispatch("flags/create", {
        name: newTag,
        color: this.tag.tagColor,
      });
      this.loadFlags();
    },
    downloadResultModal(data) {
      this.downloadResultCreditsWarningModalData = data.creditsModalData;
      this.downloadResultCreditsWarningModal = true;
    },
    async downloadResult(caseItem) {
      this.downloadResultCreditsWarningModal = false;

      this.$store.commit("showLoader", true);
      await this.$store
        .dispatch("caseLists/downloadResult", {
          caseId: caseItem.id,
        })
        .then(async (res) => {
          const response = res?.data?.case;
          const credits = res?.data?.credits;
          FileService.downloadFiles([
            response.results["upper_rotated"] || response.results["upper"],
            response.results["lower_rotated"] || response.results["lower"],
          ]);
          if (credits < 10) {
            let mailData;
            const companyId = localStorage.getItem("company_id");
            if (companyId) {
              const response = await this.$store.dispatch(
                "customers/show",
                companyId
              );
              mailData = response?.data?.modelData?.notificationMail;
            }
            this.$store.dispatch("mailTemplates/sendMailTemplate", {
              ...this.templateId,
              id: this.templateId?.mailTemplateId,
              from: this.templateId?.senderMail,
              mails: mailData
                ? [mailData, this.user?.email]
                : [this.user?.email],
              data: {
                ...response,
                credits: credits,
              },
            });
          }
        })
        .finally(() => {
          this.$store.commit("showLoader", false);
          this.getCredits();
          this.loadItems();
        });
    },
    openModal(caseItem) {
      this.selectedId = caseItem.id;
      this.tag.tagColor = caseItem.tagColor;
      this.tag.flag = caseItem.flag;
      this.showModal = true;
    },
    async getCredits() {
      const creditResponse = await this.$store.dispatch("customers/getCredits");
      this.credits = creditResponse?.data?.credits ?? 0;
    },
    toggleSelectAll() {
      if (this.selectAll) {
        // Map over rows to extract all IDs
        this.selectedCases = this.rows.map((row) => row.id);
      } else {
        this.selectedCases = [];
      }
    },
  },
  async mounted() {
    this.$store.commit("showLoader", true);
    this.loadItems();

    this.loadFlags();
    this.getCredits();

    await this.$store.dispatch("globalConfiguration/show");
    const response = await this.$store.dispatch(
      "mailTemplates/mailTemplateAssignmentList"
    );
    this.templateId =
      response?.data?.data.find(
        (template) => template.module === "lowCreditsTemplate"
      ) ?? null;
    this.$store.commit("showLoader", false);
    this.intervalId = setInterval(() => {
      this.loadItems();
    }, 30000);
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed
    clearInterval(this.intervalId);
  },
  watch: {
    selectedCases(newVal) {
      this.selectAll = newVal.length === this.rows.length;
    },
    form: {
      handler() {
        this.debouncedFetch();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
.form-dropdown {
  width: 100%;
  .dropdown-toggle {
    padding: 0 !important;
    height: auto !important;
    color: #fff;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &::after {
      display: none;
    }
  }
}
</style>