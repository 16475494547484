var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"action"},[_c('b-nav-item-dropdown',{attrs:{"right":"","toggle-class":"d-flex align-items-center"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{},[_c('ElementIcon',{attrs:{"icon":'barVerticalIcon'}})],1)]},proxy:true}])},[(_vm.canDownloadOriginal)?_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":function($event){return _vm.downloadFiles([
          _vm.caseItem.files['upper_portal_original'],
          _vm.caseItem.files['lower_portal_original'] ])}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"DownloadIcon"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t("Download Original"))+" ")])],1):_vm._e(),(_vm.canDownloadResult)?_c('div',[(_vm.caseItem.caseStatus === 'in-approval')?_c('b-dropdown-item',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top",value:(
          _vm.caseItem.caseStatus === 'in-approval'
            ? _vm.$t('Case first needs to be approved by dentaltwin')
            : null
        ),expression:"\n          caseItem.caseStatus === 'in-approval'\n            ? $t('Case first needs to be approved by dentaltwin')\n            : null\n        ",modifiers:{"top":true}}],staticClass:"cursor-not-allowed",attrs:{"disabled":"","link-class":"d-flex align-items-center"}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"DownloadIcon"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t("Download Result"))+" ")])],1):(_vm.caseItem.isUpper || _vm.caseItem.isLower)?_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":function($event){return _vm.showDownloadResultCreditsWarningModal(
            [
              _vm.caseItem.results['upper_rotated'] || _vm.caseItem.results['upper'],
              _vm.caseItem.results['lower_rotated'] || _vm.caseItem.results['lower'] ],
            _vm.caseItem
          )}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"DownloadIcon"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t("Download Result"))+" ")])],1):_vm._e()],1):_vm._e(),(_vm.$can(((_vm.$route.meta.permission) + ".edit")))?_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":function($event){_vm.status == 'finished'
          ? _vm.updateStatus(_vm.caseItem.id, 'active')
          : _vm.updateStatus(_vm.caseItem.id, 'finished')}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"CheckIcon"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.status == "finished" ? "Active" : "Finished"))+" ")])],1):_vm._e(),(_vm.$can(((_vm.$route.meta.permission) + ".recalculate")))?_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":function($event){return _vm.replicateCase(_vm.caseItem, _vm.caseItem.files)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"CopyIcon"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t("Recalculate"))+" ")])],1):_vm._e(),(
        _vm.$can("ticket.create") && _vm.$can(((_vm.$route.meta.permission) + ".report-case"))
      )?_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":function($event){return _vm.reportCase(_vm.caseItem)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"FlagIcon"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t("Report Case"))+" ")])],1):_vm._e(),(
        _vm.status == 'trash'
          ? _vm.$can(((_vm.$route.meta.permission) + ".delete"))
          : _vm.$can(((_vm.$route.meta.permission) + ".edit"))
      )?_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":function($event){_vm.status == 'trash'
          ? _vm.destroy(_vm.caseItem.id)
          : _vm.updateStatus(_vm.caseItem.id, 'trash')}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"TrashIcon"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t("Delete"))+" ")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }