<template>
  <div class="action">
    <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center"
      class=""
    >
      <template #button-content>
        <div class="">
          <ElementIcon v-bind:icon="'barVerticalIcon'" />
        </div>
      </template>
      <b-dropdown-item
        link-class="d-flex align-items-center"
        v-if="canDownloadOriginal"
        @click="
          downloadFiles([
            caseItem.files['upper_portal_original'],
            caseItem.files['lower_portal_original'],
          ])
        "
      >
        <feather-icon size="16" icon="DownloadIcon" class="mr-50" />

        <span>
          {{ $t("Download Original") }}
        </span>
      </b-dropdown-item>
      <div v-if="canDownloadResult">
        <b-dropdown-item
          v-if="caseItem.caseStatus === 'in-approval'"
          disabled
          class="cursor-not-allowed"
          v-b-tooltip.top="
            caseItem.caseStatus === 'in-approval'
              ? $t('Case first needs to be approved by dentaltwin')
              : null
          "
          link-class="d-flex align-items-center"
        >
          <feather-icon size="16" icon="DownloadIcon" class="mr-50" />

          <span>
            {{ $t("Download Result") }}
          </span>
        </b-dropdown-item>
        <b-dropdown-item
          v-else-if="caseItem.isUpper || caseItem.isLower"
          @click="
            showDownloadResultCreditsWarningModal(
              [
                caseItem.results['upper_rotated'] || caseItem.results['upper'],
                caseItem.results['lower_rotated'] || caseItem.results['lower'],
              ],
              caseItem
            )
          "
          link-class="d-flex align-items-center"
        >
          <feather-icon size="16" icon="DownloadIcon" class="mr-50" />

          <span>
            {{ $t("Download Result") }}
          </span>
        </b-dropdown-item>
      </div>
      <b-dropdown-item
        @click="
          status == 'finished'
            ? updateStatus(caseItem.id, 'active')
            : updateStatus(caseItem.id, 'finished')
        "
        link-class="d-flex align-items-center"
        v-if="$can(`${$route.meta.permission}.edit`)"
      >
        <feather-icon size="16" icon="CheckIcon" class="mr-50" />

        <span>
          {{ $t(status == "finished" ? "Active" : "Finished") }}
        </span>
      </b-dropdown-item>
      <b-dropdown-item
        @click="replicateCase(caseItem, caseItem.files)"
        link-class="d-flex align-items-center"
        v-if="$can(`${$route.meta.permission}.recalculate`)"
      >
        <feather-icon size="16" icon="CopyIcon" class="mr-50" />

        <span>
          {{ $t("Recalculate") }}
        </span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="
          $can(`ticket.create`) && $can(`${$route.meta.permission}.report-case`)
        "
        link-class="d-flex align-items-center"
        @click="reportCase(caseItem)"
      >
        <feather-icon size="16" icon="FlagIcon" class="mr-50" />
        <span>
          {{ $t("Report Case") }}
        </span>
      </b-dropdown-item>
      <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="
          status == 'trash'
            ? destroy(caseItem.id)
            : updateStatus(caseItem.id, 'trash')
        "
        v-if="
          status == 'trash'
            ? $can(`${$route.meta.permission}.delete`)
            : $can(`${$route.meta.permission}.edit`)
        "
      >
        <feather-icon size="16" icon="TrashIcon" class="mr-50" />
        <span>
          {{ $t("Delete") }}
        </span>
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import FileService from "@/services/file.service";
import ElementIcon from "@/components/elements/Icon.vue";
export default {
  emits: [
    "loadItems",
    "showCreditsModal",
    "getCredits",
    "downloadResultModal",
    "showCaseModal",
  ],
  components: {
    ElementIcon,
  },
  props: {
    caseItem: {
      type: Object,
      required: true,
    },
    credits: {
      type: [Number, String],
      default: 0,
    },
    status: {
      type: String,
    },
    configuration: {
      type: Object,
    },
  },
  computed: {
    canDownloadOriginal() {
      return (
        (this.caseItem.files["upper_portal_original"] ||
          this.caseItem.files["lower_portal_original"]) &&
        this.$can(`${this.$route.meta.permission}.download-original`)
      );
    },
    canDownloadResult() {
      return (
        this.caseItem.results &&
        this.caseItem.caseStatus != "in-progress" &&
        this.$can(`${this.$route.meta.permission}.download-result`)
      );
    },
  },
  methods: {
    downloadFiles(array) {
      FileService.downloadFiles(array);
    },
    async replicateCase(caseItem, files) {
      var sumCredits = 0;
      if (files["upper"] != null) {
        sumCredits =
          parseFloat(this.configuration?.costsPerUpperJaw ?? 0.0) *
          parseFloat(caseItem?.amountReconstructedUpperTeeth ?? 0.0);
      }

      if (files["lower"] != null) {
        sumCredits =
          sumCredits +
          parseFloat(this.configuration?.costsPerLowerJaw ?? 0.0) *
            parseFloat(caseItem?.amountReconstructedLowerTeeth ?? 0.0);
      }
      if (
        this.configuration?.maximumOverallCosts &&
        sumCredits > parseFloat(this.configuration?.maximumOverallCosts ?? 0.0)
      ) {
        sumCredits = parseFloat(this.configuration?.maximumOverallCosts ?? 0.0);
      }
      if (caseItem.isPaid) {
        if (this.configuration?.recalculationFactor)
          sumCredits =
            sumCredits *
            parseFloat(this.configuration?.recalculationFactor ?? 0.0);
      }
      if (sumCredits > parseFloat(this.credits)) {
        this.$emit("showCreditsModal");
      } else {
        const confirmed = await this.showConfirmationDialog({
          title: this.$t("Do you want to recalculate this record?"),
          subText: this.$t("Yes, recalculate it!"),
          text: "It will cost you " + sumCredits + " DentalTwin Coins",
        });

        if (confirmed) {
          await this.$store
            .dispatch("caseLists/replicateCase", {
              caseId: caseItem.id,
              extensions: [{ id: "opg-to-3d" }],
            })
            .finally(() => {
              this.$emit("getCredits");
              this.$emit("loadItems");
            });
        }
      }
    },
    async destroy(id) {
      const confirmed = await this.showConfirmationDialog({
        title: this.$t("Do you want to delete this record?"),
        subText: this.$t("Yes delete it!"),
      });

      if (confirmed) {
        await this.$store.dispatch("caseLists/destroy", id).finally(() => {
          this.$emit("loadItems");
        });
      }
    },
    reportCase(reportedCase) {
      this.$store.commit("caseLists/reportCaseID", reportedCase);
      this.$router.push("/tickets/create");
    },
    showDownloadResultCreditsWarningModal(array, caseItem) {
      if (caseItem?.isPaid) {
        FileService.downloadFiles(array);
      } else {
        let sum = 0;
        if (caseItem.isUpper) {
          sum =
            parseFloat(this.configuration?.costsPerUpperJaw ?? 0.0) *
            parseFloat(caseItem?.amountReconstructedUpperTeeth ?? 0.0);
        }
        if (caseItem.isLower) {
          sum +=
            parseFloat(this.configuration?.costsPerLowerJaw ?? 0.0) *
            parseFloat(caseItem?.amountReconstructedLowerTeeth ?? 0.0);
        }
        if (
          this.configuration?.maximumOverallCosts &&
          sum > parseFloat(this.configuration?.maximumOverallCosts)
        ) {
          sum = parseFloat(this.configuration?.maximumOverallCosts);
        }
        if (sum > parseFloat(this.credits)) {
          this.$emit("showCaseModal");
          return;
        }
        const creditsModalData = {
          caseItem: caseItem,
          sum: +sum,
        };

        this.$emit("downloadResultModal", { creditsModalData });
      }
    },
    async updateStatus(id, status) {
      const title =
        status === "trash"
          ? this.$t("Do you want to delete this record?")
          : this.$t("Do you want to update this record?");
      const subText =
        status === "trash"
          ? this.$t("Yes delete it!")
          : this.$t("Yes update it!");
      const confirmed = await this.showConfirmationDialog({
        title: title,
        subText: subText,
      });

      if (confirmed) {
        await this.$store
          .dispatch("caseLists/updateStatus", {
            caseId: id,
            status: status,
          })
          .finally(() => {
            this.$emit("loadItems");
          });
      }
    },
  },
};
</script>
